import { reactive, defineComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import Star from "@/presentation/components/star/Star.vue";
import ButtonLoadMore from "@/presentation/components/button/load-more/BtnLoadMore.vue";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
import DIContainer from "@/core/DIContainer";
import TransformUtil from "@/commons/TransformUtil";
import RoutePath from "@/commons/RoutePath";
import CommonService from '@/services/CommonService';
import ParamsSearchProject from '@/domain/entity/ParamsSearchProject';
import noPhoto from "@/assets/images/common/error_project.png";
import TablePreload from "@/presentation/components/preloader/table-preload/TablePreload.vue";
import LanguageUtil from "@/commons/LanguageUtil";
export default defineComponent({
    name: "OrganizationProfileRecruiting",
    components: { Star: Star, ButtonLoadMore: ButtonLoadMore, TablePreload: TablePreload },
    props: ["organizationInfo", "breadcrumb"],
    setup: function (props) {
        var router = useRouter();
        var route = useRoute();
        var organizationInteractor = DIContainer.instance.get(OrganizationInteractor);
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            total_count: 0,
            limit: CommonService.getPerPageNumber(),
            page: 1,
            listReview: null,
            listProjects: [],
            isLoading: false,
            totalClientRecord: 0,
            fieldSortName: "",
            fieldSortType: "",
            preload: true,
            organizationId: ""
        });
        function getListProject(action) {
            var params = new ParamsSearchProject();
            var user = CommonService.getCookie("user");
            var user_infor = {};
            if (user) {
                user_infor = JSON.parse(decodeURIComponent(user));
                params["filter[organization][]"] = user_infor.organization_id;
            }
            params.limit = state.limit;
            params.page = state.page;
            params["order[column]"] = state.fieldSortName;
            params["order[type]"] = state.fieldSortType;
            params["filter[status][]"] = "recruiting";
            params.account_id = state.organizationId;
            state.isLoading = true;
            organizationInteractor
                .getOrganProjects(params)
                .then(function (result) {
                if (action == "mounted") {
                    state.preload = false;
                }
                if (!result.data) {
                    return "";
                }
                if (result.data.length == 0) {
                    return "";
                }
                state.isLoading = false;
                result.data.forEach(function (element) {
                    element.working_period_start_at = TransformUtil.convertDatetime(element.working_period_start_at);
                    element.working_period_end_at = TransformUtil.convertDatetime(element.working_period_end_at);
                    element.title = TransformUtil.convertShortText(element.title);
                });
                state.isLoading = false;
                if (action == 'sort') {
                    state.listProjects = result.data;
                }
                else {
                    state.listProjects = state.listProjects.concat(result.data);
                }
                state.total_count = result.total_count;
                state.totalClientRecord = state.listProjects.length;
            })
                .catch(function (error) {
                state.isLoading = false;
                if (action == "mounted") {
                    state.preload = false;
                }
            });
        }
        function loadMore() {
            state.page = state.page + 1;
            getListProject();
        }
        function setLimit(limit) {
            CommonService.setPerPageNumber(limit);
            state.limit = limit;
            state.page = 1;
            getListProject('sort');
        }
        function sortTable(field) {
            if (field != state.fieldSortName) {
                state.fieldSortType = "asc";
            }
            else {
                state.fieldSortType = state.fieldSortType == "desc" ? "asc" : "desc";
            }
            state.page = 1;
            state.fieldSortName = field;
            getListProject("sort");
        }
        onMounted(function () {
            state.organizationId = route.params.organizationId;
            getListProject('mounted');
            props.breadcrumb[2] = { name: "募集中のプロジェクト", route: "" };
        });
        function replaceByDefault(e) {
            e.target.src = noPhoto;
        }
        return {
            state: state,
            props: props,
            RoutePath: RoutePath,
            setLimit: setLimit,
            loadMore: loadMore,
            sortTable: sortTable,
            replaceByDefault: replaceByDefault,
            noPhoto: noPhoto
        };
    }
});
